import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ButtonNavLink from "../../../../../components/ButtonNavLink";
import { checkType } from "../../../../../utils/checkType";
import { ChevronDown, ChevronUp, Menu } from "react-feather";
import { useStoreState } from "easy-peasy";
import { Popup } from "semantic-ui-react";
import {
  addToBasket,
  addFlatToBriefcase,
} from "../../../../../utils/customerInteraction";
import { editPrice } from "../../../../../utils/numbers";
import { useHistory } from "react-router-dom";
import cs from "classnames";
const FlatListView = ({ flats, sortType, setSortType }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [filteredData, setFilteredData] = useState([]);

  const { briefcaseClient } = useStoreState((state) => state.briefcase);
  const { currentBasketUuid } = useStoreState((state) => state.baskets);
  const userRole = useStoreState((state) => state.auth.user?.role);

  useEffect(() => {
    let newArr = [...flats];
    switch (sortType) {
      case "price_up":
        newArr.sort((a, b) => {
          return a.price < b.price ? -1 : 1;
        });
        break;

      case "price_down":
        newArr.sort((a, b) => {
          return a.price > b.price ? -1 : 1;
        });
        break;

      case "price_per_m_up":
        newArr.sort((a, b) => {
          return a.pricemkw < b.pricemkw ? -1 : 1;
        });
        break;

      case "price_per_m_down":
        newArr.sort((a, b) => {
          return a.pricemkw > b.pricemkw ? -1 : 1;
        });
        break;

      case "area_up":
        newArr.sort((a, b) => {
          return a.area < b.area ? -1 : 1;
        });
        break;

      case "area_down":
        newArr.sort((a, b) => {
          return a.area > b.area ? -1 : 1;
        });
        break;
      case "floor_down":
        newArr.sort((a, b) => {
          return a.floor > b.floor ? -1 : 1;
        });
        break;
      case "floor_up":
        newArr.sort((a, b) => {
          return a.floor < b.floor ? -1 : 1;
        });
        break;
      case "rooms_down":
        newArr.sort((a, b) => {
          return a.rooms > b.rooms ? -1 : 1;
        });
        break;
      case "rooms_up":
        newArr.sort((a, b) => {
          return a.rooms < b.rooms ? -1 : 1;
        });
        break;

      default:
        break;
    }
    setFilteredData(newArr);
  }, [sortType, flats]);

  const handleSortRooms = () => {
    if (sortType === "rooms_up") {
      setSortType("rooms_down");
    } else setSortType("rooms_up");
  };

  const handleSortFloor = () => {
    if (sortType === "floor_up") {
      setSortType("floor_down");
    } else setSortType("floor_up");
  };

  const handleSortPrice = () => {
    if (sortType === "price_up") {
      setSortType("price_down");
    } else setSortType("price_up");
  };

  const handleSortPricemkw = () => {
    if (sortType === "price_per_m_up") {
      setSortType("price_per_m_down");
    } else setSortType("price_per_m_up");
  };

  const handleSortArea = () => {
    if (sortType === "area_up") {
      setSortType("area_down");
    } else setSortType("area_up");
  };

  return (
    <>
      {flats?.length !== 0 ? (
        <div id="flats">
          <table className="text-left w-full max-h-40 py-5">
            <thead className="font-bold uppercase text-xs flex w-full">
              <tr className="flex w-full mb-4">
                <th className="py-4 px-4 border-b w-2/12">
                  {t("Realestate name")}
                </th>
                <th className="py-4 px-4 border-b w-1/12">{t("Status")}</th>

                <th className="py-4 px-4 border-b w-2/12">
                  {
                    <button
                      className="leading-5 flex font-bold uppercase text-xs items-center"
                      value="price"
                      onClick={(e) => handleSortPrice(e)}
                    >
                      {t("Price")}
                      {sortType === "price_down" ? (
                        <ChevronDown size={14} />
                      ) : (
                        <ChevronUp size={14} />
                      )}
                    </button>
                  }
                </th>
                <th className=" py-4 px-4 border-b w-2/12 whitespace-nowrap ">
                  <button
                    className="leading-5 flex font-bold uppercase text-xs items-center"
                    value="pricemkw"
                    onClick={(e) => handleSortPricemkw(e)}
                  >
                    {t("Price per m")}
                    <sup>2</sup>
                    {sortType === "price_per_m_down" ? (
                      <ChevronDown size={14} />
                    ) : (
                      <ChevronUp size={14} />
                    )}
                  </button>
                </th>
                <th className="py-4 px-4 border-b w-2/12">
                  <button
                    className="leading-5 flex font-bold uppercase text-xs items-center"
                    value="area"
                    onClick={(e) => handleSortArea(e)}
                  >
                    {t("Area")}
                    {sortType === "area_down" ? (
                      <ChevronDown size={14} />
                    ) : (
                      <ChevronUp size={14} />
                    )}
                  </button>
                </th>
                <th className="py-4 px-4 border-b w-1/12 ">
                  {" "}
                  <button
                    className="leading-5 flex font-bold uppercase text-xs items-center"
                    value="rooms"
                    onClick={(e) => handleSortRooms(e)}
                  >
                    {t("Rooms")}
                    {sortType === "rooms_down" ? (
                      <ChevronDown size={14} />
                    ) : (
                      <ChevronUp size={14} />
                    )}
                  </button>
                </th>
                <th className="py-4 px-4 border-b w-1/12">
                  {" "}
                  <button
                    className="leading-5 flex font-bold uppercase text-xs items-center"
                    value="floor"
                    onClick={(e) => handleSortFloor(e)}
                  >
                    {t("Floor")}
                    {sortType === "floor_down" ? (
                      <ChevronDown size={14} />
                    ) : (
                      <ChevronUp size={14} />
                    )}
                  </button>
                </th>
                <th className="py-4 px-4 border-b w-1/12">{t("Type")}</th>
                <th className="py-4 px-4 border-b w-1/12"></th>
              </tr>
            </thead>
            <div className="overflow-y-scroll w-full max-h-40">
              <tbody className="table text-sm flex flex-col items-center justify-between overflow-y-scroll w-full max-h-40">
                {filteredData.map((el) => (
                  <tr key={el.id} className="flex w-full h-16">
                    <td className="py-3 px-4 border-b w-2/12 flex items-center">
                      <ButtonNavLink
                        uuid={el.uuid}
                        link={`/realestate/${el.uuid}`}
                        name={`${el?.building ? `${el?.building} - ` : " "}${
                          el?.name
                        }`}
                      />
                    </td>
                    <td className="py-3 px-4 border-b w-1/12 flex items-center">
                      {t(el.status)}
                    </td>

                    <td className="py-3 px-4 border-b w-2/12 flex items-center">
                      {el?.price || el.status === "Available" ? (
                        el?.price && el?.ask_for_price !== "1" ? (
                          <p
                            className={cs(
                              el?.is_in_promotion === "1" &&
                                "text-red-600 font-semibold"
                            )}
                          >
                            {editPrice(el.price)} PLN
                          </p>
                        ) : (
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              history.push(
                                `/questions?r_uuid=${el.uuid}&i_uuid=${el.investment_uuid}`
                              );
                            }}
                          >
                            {t("Ask about price")}
                          </span>
                        )
                      ) : (
                        t("No data")
                      )}
                    </td>
                    <td className="py-3 px-4 border-b w-2/12 flex items-center whitespace-nowrap">
                      {el?.price || el.status === "Available" ? (
                        <>
                          {el?.pricemkw && el?.ask_for_price !== "1" ? (
                            `${
                              el.pricemkw
                                ? `${editPrice(el.pricemkw)} PLN`
                                : t("No data")
                            }`
                          ) : (
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                history.push(
                                  `/questions?r_uuid=${el.uuid}&i_uuid=${el.investment_uuid}`
                                );
                              }}
                            >
                              {t("Ask about price")}
                            </span>
                          )}

                          {el?.pricemkw && el?.ask_for_price !== "1" && (
                            <sup>2</sup>
                          )}
                        </>
                      ) : (
                        t("No data")
                      )}
                    </td>
                    <td className="py-3 px-4 border-b w-2/12 flex items-center">
                      {el.area ? `${el.area} m` : t("No data")}
                      {el.area && <sup>2</sup>}
                    </td>
                    <td className="py-3 px-4 border-b w-1/12 flex items-center">
                      {el.rooms ? el.rooms : t("not applicable")}
                    </td>
                    <td className="py-3 px-4 border-b w-1/12 flex items-center">
                      {el.floor}
                    </td>
                    <td className="py-3 px-4 border-b w-1/12 flex items-center">
                      {el.type && t(checkType(el.type))}
                    </td>

                    <td className="py-3 px-4 border-b w-1/12 flex items-center">
                      <Popup
                        trigger={
                          <div className="mr-2.5 text-primse-green relative w-8 cursor-pointer">
                            <Menu
                              className="ml-2 text-primse-green z-10 cursor-pointer"
                              size={24}
                            />
                          </div>
                        }
                        flowing
                        on="click"
                        position="right center"
                      >
                        <div className="bg-white  text-sm font-normal">
                          <div className="flex flex-col">
                            {el &&
                            userRole !== "Deweloper" &&
                            el?.status === "Available" ? (
                              <>
                                <button
                                  className="leading-5 my-2 font-bold text-black uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                                  onClick={(e) => {
                                    addToBasket(
                                      e,
                                      el.uuid,
                                      currentBasketUuid,
                                      el.name
                                    );
                                  }}
                                >
                                  {t("Add to basket")}
                                </button>
                                <button
                                  className="leading-5 my-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                                  onClick={() => {
                                    addFlatToBriefcase(
                                      el.uuid,
                                      el.name,
                                      briefcaseClient
                                    );
                                  }}
                                >
                                  {t("Add to client briefcase")}
                                </button>{" "}
                              </>
                            ) : null}
                            <button
                              className="leading-5 my-2 font-bold  text-white uppercase bg-primse-gray py-3 px-14 rounded-sm transition-all hover:bg-primse-green hover:text-white"
                              onClick={() => {
                                history.push(`/realestate/${el.uuid}`);
                              }}
                            >
                              {t("Details")}
                            </button>
                          </div>
                        </div>
                      </Popup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </div>
          </table>
        </div>
      ) : (
        <div className="text-sm w-full py-8 px-4 ">
          {t("There are no matching premises in the investment")}
        </div>
      )}
    </>
  );
};

FlatListView.propTypes = {
  flats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uuid: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.string,
      pricemkw: PropTypes.string,
      area: PropTypes.string,
      rooms: PropTypes.string,
      floor: PropTypes.string,
      status: PropTypes.string,
      investment_id: PropTypes.string,
      investment_uuid: PropTypes.string,
      investment_city: PropTypes.string,
      investment_lat: PropTypes.string,
      investment_long: PropTypes.string,
      plan: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

export default FlatListView;

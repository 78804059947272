import axios from "../utils/axios";

const InvestmentsService = {
  getInvestments: async (
    params,
    page = 1,
    perPage = 500,
    withPaginator = false
  ) => {
    return await axios
      .get(`/investment-index?page=${page}&perPage=${perPage}`, {
        params: { ...params, realestate_status_id: ["1"], order: "id,desc" },
      })
      .then((res) => {
        if (withPaginator) return res?.data;
        return res?.data?.payload;
      });
  },
  getInvestment: (uuid) => async () => {
    return await axios
      .get(`/investment-view/${uuid}`)
      .then((res) => res?.data?.payload);
  },
  getInvestmentTerms: (investment_uuid) => async () => {
    return await axios
      .get(`/investment-agreement-terms/${investment_uuid}`)
      .then((res) => res?.data?.payload);
  },
};

export default InvestmentsService;

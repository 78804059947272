import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { BriefCasePlus } from "./../../Icons/index";
import { useStoreState } from "easy-peasy";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  addToBasket,
  addFlatToBriefcase,
} from "../../../utils/customerInteraction";
import { editPrice } from "../../../utils/numbers";
import { Popup } from "semantic-ui-react";
import { checkType } from "../../../utils/checkType";
import ButtonNavLink from "../../ButtonNavLink";
import DetailsInvestments from "../../DetailsInvestments";
import { sortRealestates } from "../../../utils/sortUtils";
import cs from "classnames";

const FlatListView = ({ flats }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [areaIsSorted, setAreaIsSorted] = useState(false);
  const [roomsIsSorted, setRoomsIsSorted] = useState(false);
  const [floorIsSorted, setFloorIsSorted] = useState(false);
  const [priceIsSorted, setPriceIsSorted] = useState(false);
  const [pricemkwIsSorted, setPricemkwIsSorted] = useState(false);

  const { t } = useTranslation();
  useEffect(() => {
    setFilteredData(flats.sort(sortRealestates));
  }, [flats]);
  const history = useHistory();

  const handleSortArea = (event) => {
    const arr = [...flats];
    arr.sort((a, b) => {
      if (areaIsSorted === false) {
        return a[event.currentTarget.value] - b[event.currentTarget.value];
      } else return b[event.currentTarget.value] - a[event.currentTarget.value];
    });
    setFilteredData(arr);
    if (areaIsSorted === false) {
      setAreaIsSorted(true);
    } else setAreaIsSorted(false);
  };

  const handleSortRooms = (event) => {
    const arr = [...flats];
    arr.sort((a, b) => {
      if (roomsIsSorted === false) {
        return a[event.currentTarget.value] > b[event.currentTarget.value]
          ? -1
          : 1;
      } else
        return a[event.currentTarget.value] < b[event.currentTarget.value]
          ? -1
          : 1;
    });
    setFilteredData(arr);
    if (roomsIsSorted === false) {
      setRoomsIsSorted(true);
    } else setRoomsIsSorted(false);
  };

  const handleSortFloor = (event) => {
    const arr = [...flats];
    arr.sort((a, b) => {
      if (floorIsSorted === false) {
        return a[event.currentTarget.value] > b[event.currentTarget.value]
          ? -1
          : 1;
      } else
        return a[event.currentTarget.value] < b[event.currentTarget.value]
          ? -1
          : 1;
    });
    setFilteredData(arr);
    if (floorIsSorted === false) {
      setFloorIsSorted(true);
    } else setFloorIsSorted(false);
  };

  const handleSortPrice = (event) => {
    const arr = [...flats];
    arr.sort((a, b) => {
      if (priceIsSorted === false) {
        return a[event?.currentTarget?.value] * 1 >
          b[event?.currentTarget?.value] * 1
          ? -1
          : 1;
      } else
        return a[event?.currentTarget?.value] * 1 <
          b[event?.currentTarget?.value] * 1
          ? -1
          : 1;
    });
    setFilteredData(arr);
    if (priceIsSorted === false) {
      setPriceIsSorted(true);
    } else setPriceIsSorted(false);
  };

  const handleSortPricemkw = (event) => {
    const arr = [...flats];
    arr.sort((a, b) => {
      if (pricemkwIsSorted === false) {
        return a[event?.currentTarget?.value] * 1 >
          b[event?.currentTarget?.value] * 1
          ? -1
          : 1;
      } else
        return a[event?.currentTarget?.value] * 1 <
          b[event?.currentTarget?.value] * 1
          ? -1
          : 1;
    });
    setFilteredData(arr);
    if (pricemkwIsSorted === false) {
      setPricemkwIsSorted(true);
    } else setPricemkwIsSorted(false);
  };

  const { briefcaseClient } = useStoreState((state) => state.briefcase);
  const { currentBasketUuid } = useStoreState((state) => state.baskets);
  const userRole = useStoreState((state) => state.auth.user?.role);

  return (
    <div className="pt-12" id="flats">
      <h4 className="text-xl font-bold">{t("Available apartments")}</h4>

      <table className="text-left w-full ">
        <thead className="font-bold uppercase text-xs flex w-full">
          <tr className="flex w-full mb-4">
            <th className="py-4 px-4 border-b w-1/12">{t("Name")}</th>
            <th className="py-4 px-4 border-b w-1/12">{t("Status")}</th>
            <th className="py-4 px-4 border-b w-2/12">
              <button
                className="leading-5 flex font-bold uppercase text-xs items-center"
                value="price"
                onClick={handleSortPrice}
              >
                {t("Price")}
                {priceIsSorted ? (
                  <ChevronDown size={14} />
                ) : (
                  <ChevronUp size={14} />
                )}
              </button>
            </th>
            <th className="py-4 px-4 border-b w-2/12 whitespace-nowrap">
              <button
                className="leading-5 flex font-bold uppercase text-xs items-center"
                value="pricemkw"
                onClick={handleSortPricemkw}
              >
                {t("Price per m")}
                <sup>2</sup>
                {pricemkwIsSorted ? (
                  <ChevronDown size={14} />
                ) : (
                  <ChevronUp size={14} />
                )}
              </button>
            </th>
            <th className="py-4 px-4 border-b w-1/12">
              <button
                className="leading-5 flex font-bold uppercase text-xs items-center"
                value="area"
                onClick={handleSortArea}
              >
                {t("Area")}
                {areaIsSorted ? (
                  <ChevronDown size={14} />
                ) : (
                  <ChevronUp size={14} />
                )}
              </button>
            </th>
            <th className="py-4 px-4 border-b w-1/12 ">
              <button
                className="leading-5 flex font-bold uppercase text-xs items-center"
                value="rooms"
                onClick={handleSortRooms}
              >
                {t("Rooms")}
                {roomsIsSorted ? (
                  <ChevronDown size={14} />
                ) : (
                  <ChevronUp size={14} />
                )}
              </button>
            </th>
            <th className="py-4 px-4 border-b w-1/12">
              <button
                className="leading-5 flex font-bold uppercase text-xs items-center"
                value="floor"
                onClick={handleSortFloor}
              >
                {t("Floor")}
                {floorIsSorted ? (
                  <ChevronDown size={14} />
                ) : (
                  <ChevronUp size={14} />
                )}
              </button>
            </th>
            <th className="py-4 px-4 border-b w-1/12">{t("Type")}</th>
            <th className="py-4 px-4 border-b w-1/12">{t("Details")}</th>
            <th className="py-4 px-4 border-b w-1/12"></th>
          </tr>
        </thead>
        <div
          //I don't like it but it is the fastest way to make it look ok on Safari
          style={{ height: filteredData?.length > 10 && "50vh" }}
          className="overflow-y-scroll"
        >
          <tbody className="text-sm flex flex-col items-center justify-between w-full ">
            {filteredData.map(
              ({
                uuid,
                name,
                price,
                pricemkw,
                area,
                rooms,
                floor,
                type,
                plan,
                pdf,
                status,
                building,
                ask_for_price,
                investment_uuid,
                is_in_promotion,
              }) => (
                <tr
                  key={uuid}
                  className="flex w-full hover:bg-primse-light-green"
                >
                  <td className="py-3 px-4 border-b w-1/12">
                    <ButtonNavLink
                      uuid={uuid}
                      link={`/realestate/${uuid}`}
                      name={`${building ? `${building} - ` : " "}${name}`}
                    />
                  </td>
                  <td className="py-3 px-4 border-b w-1/12">{t(status)}</td>
                  <td className="py-3 px-4 border-b w-2/12">
                    {price || status === "Available" ? (
                      price && ask_for_price !== "1" ? (
                        <p
                          className={cs(
                            is_in_promotion === "1" &&
                              "text-red-600 font-semibold"
                          )}
                        >
                          {editPrice(price)} PLN
                        </p>
                      ) : (
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            history.push(
                              `/questions?r_uuid=${uuid}&i_uuid=${investment_uuid}`
                            );
                          }}
                        >
                          {t("Ask about price")}
                        </span>
                      )
                    ) : (
                      t("No data")
                    )}
                  </td>
                  <td className="py-3 px-4 border-b w-2/12 whitespace-nowrap">
                    {price || status === "Available" ? (
                      pricemkw && ask_for_price !== "1" ? (
                        `${editPrice(pricemkw)} PLN`
                      ) : (
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            history.push(
                              `/questions?r_uuid=${uuid}&i_uuid=${investment_uuid}`
                            );
                          }}
                        >
                          {t("Ask about price")}
                        </span>
                      )
                    ) : (
                      t("No data")
                    )}
                  </td>
                  <td className="py-3 px-4 border-b w-1/12">
                    {area ? `${area} m` : t("No data")}
                    {area && <sup>2</sup>}
                  </td>
                  <td className="py-3 px-4 border-b w-1/12">
                    {rooms ? rooms : t("not applicable")}
                  </td>
                  <td className="py-3 px-4 border-b w-1/12">{floor}</td>
                  <td className="py-3 px-4 border-b w-1/12">
                    {type && t(checkType(type))}
                  </td>
                  <td className="py-3 px-4 border-b w-1/12">
                    <DetailsInvestments
                      PDFLink={pdf}
                      PDFName={name}
                      planLink={plan}
                      uuid={uuid}
                      pathname={history.location.pathname}
                    />
                  </td>
                  <td className="py-3 px-4 border-b w-1/12">
                    {userRole !== "Deweloper" && status === "Available" ? (
                      <Popup
                        trigger={
                          <div className="cursor-pointer text-white flex items-center justify-center duration-300 hover:opacity-90">
                            <div className="w-8 ">
                              <BriefCasePlus />
                            </div>
                          </div>
                        }
                        flowing
                        on="click"
                        position="right center"
                      >
                        <div className="bg-white  text-sm font-normal">
                          <div className="flex flex-col">
                            <button
                              className="leading-5 my-2 font-bold text-black uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                              onClick={(e) => {
                                addToBasket(e, uuid, currentBasketUuid, name);
                              }}
                            >
                              {t("Add to basket")}
                            </button>
                            <button
                              className="leading-5 my-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                              onClick={() => {
                                addFlatToBriefcase(uuid, name, briefcaseClient);
                              }}
                            >
                              {t("Add to client briefcase")}
                            </button>
                          </div>
                        </div>
                      </Popup>
                    ) : null}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </div>
      </table>
    </div>
  );
};

FlatListView.propTypes = {
  flats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uuid: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.string,
      pricemkw: PropTypes.string,
      area: PropTypes.string,
      rooms: PropTypes.string,
      floor: PropTypes.string,
      status: PropTypes.string,
      investment_id: PropTypes.string,
      investment_uuid: PropTypes.string,
      investment_city: PropTypes.string,
      investment_lat: PropTypes.string,
      investment_long: PropTypes.string,
      plan: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

export default FlatListView;

import { Popup } from "semantic-ui-react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import BasketsService from "../../services/BasketsService";
import BriefcaseContentService from "../../services/BriefcaseContentService";
import { actions } from "../../store";
import NewRequestModal from "../../modals/NewRequestModal";

import React, { useState, useEffect } from "react";
import {
  addInvestmentToBasket,
  addToBasket,
  addFlatToBriefcase,
} from "../../utils/customerInteraction";

import Loader from "../Loader";
import { useHistory } from "react-router-dom";

const PopupTrigger = ({ ...rest }) => {
  return <PopupView {...rest} />;
};

const PopupView = ({
  children,
  uuid,
  type = "mapButton",
  investmentData,
  typeButton = "default",
  uuidDeleteFromBasket,
  aftermarket,
  handleRefreshBasket,
  openRequestModal = false,
  currentBasketMore = false,
  hiddenRequestButton = false,
  enabledLink = true,
}) => {
  const { t } = useTranslation();
  const { briefcaseClient } = useStoreState((state) => state.briefcase);
  const { currentBasketUuid } = useStoreState((state) => state.baskets);
  const userRole = useStoreState((state) => state.auth.user?.role);
  const { isLoading } = useStoreState((state) => state.isLoading);
  const requestModal = useStoreState((state) => state.modals.requestModal);

  const [modalOpen, setModalOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [currentButton, setCurrentButton] = useState(null);

  const history = useHistory();

  const handleRefresh = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    if (requestModal) {
      setModalOpen(requestModal);
    }
  }, [requestModal]);

  const addInvestmentToBriefcase = (object_uuid, investment_name) => {
    if (briefcaseClient) {
      BriefcaseContentService.addRealestate({
        contact: briefcaseClient.uuid,
        object_uuid: object_uuid,
        object_type: `${aftermarket ? "realestate" : "investment"}`,
      })
        .then((res) => {
          if (res?.response?.status === 400) {
            if (res?.response?.data?.isDuplicated) {
              toast.error(`${t("The investment is already in the briefcase")}`);
            } else {
              toast.error(
                `${t("The investment could not be added to briefcase")}`
              );
            }
            setPopupOpen(false);
          } else {
            toast.success(
              `${t("The investment")} ${investment_name} ${t(
                "has been successfully added to the briefcase"
              )}`
            );
            setPopupOpen(false);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.isDuplicated) {
            toast.error(`${t("The investment is already in the briefcase")}`);
          } else {
            toast.error(
              `${t("The investment could not be added to briefcase")}`
            );
          }
        })
        .finally(() => actions.isLoading.setIsLoading(false));
    } else {
      actions.isLoading.setIsLoading(false);
      setPopupOpen(false);
      toast.warning(
        <div className="text-base">
          {t("Choose client briefcase")}
          <button
            className="leading-5 my-2 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
            onClick={() => {
              actions.briefcase.setBriefcaseModalSelectClient(true);
              actions.briefcase.setBriefcaseModal(true);
            }}
          >
            {t("Choose briefcase")}
          </button>
        </div>
      );
    }
  };

  const deleteFromBasket = (basket_item_uuid) => {
    BasketsService.removeItemFromBasket({
      basket_item_uuid: basket_item_uuid,
    }).then((res) => {
      if (res.response.status === 410) {
        actions.baskets.deleteItemFromBasket(basket_item_uuid);
        toast.success(
          `${t("Item from the cart has been successfully deleted")}`
        );

        if (typeButton === "deleteBasket") {
          setTimeout(() => {
            handleRefreshBasket(basket_item_uuid);
          }, 0);
        }
      } else {
        toast.error(`${t("Item could not be deleted")}`);
      }
    });
  };

  return (
    <>
      {modalOpen && currentButton === uuid ? (
        <NewRequestModal
          investment_uuid={uuid}
          clientUuid={briefcaseClient.uuid}
          developerName={investmentData.developer_name}
          investmentName={investmentData.name}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleRefresh={handleRefresh}
        />
      ) : (
        <Popup
          trigger={children}
          flowing
          on="click"
          open={popupOpen}
          onOpen={() => setPopupOpen(true)}
          onClose={() => setPopupOpen(false)}
          position={type === "button" ? "left center" : "right center"}
          hideOnScroll
        >
          {typeButton === "default" && (
            <div className={cs("bg-white  text-sm font-normal")}>
              <div className="flex flex-col">
                {aftermarket ? (
                  <button
                    className="leading-5 my-2 font-bold text-white uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                    onClick={(e) => {
                      addToBasket(
                        e,
                        uuid,
                        currentBasketUuid,
                        investmentData.name
                      );
                      setPopupOpen(false);
                    }}
                  >
                    {t("Add to basket")}
                  </button>
                ) : (
                  <button
                    className="leading-5 my-2 font-bold text-white uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                    onClick={() => {
                      addInvestmentToBasket(
                        currentBasketUuid,
                        uuid,
                        investmentData.name
                      );
                      setPopupOpen(false);
                    }}
                  >
                    {t("Add to basket")}
                  </button>
                )}

                {aftermarket ? (
                  <button
                    className="leading-5 my-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                    onClick={() => {
                      addFlatToBriefcase(
                        uuid,
                        investmentData.name,
                        briefcaseClient
                      );
                      setPopupOpen(false);
                    }}
                  >
                    {t("Add to client briefcase")}
                  </button>
                ) : (
                  <button
                    className="leading-5 my-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                    onClick={() => {
                      addInvestmentToBriefcase(uuid, investmentData.name);
                      setPopupOpen(false);
                    }}
                  >
                    {t("Add to client briefcase")}
                  </button>
                )}
                <>
                  {!hiddenRequestButton && (
                    <>
                      {briefcaseClient ? (
                        <button
                          onClick={() => {
                            setCurrentButton(uuid);
                            setPopupOpen(false);
                            setModalOpen(!modalOpen);
                          }}
                          className="leading-5 my-2 font-bold text-white uppercase bg-primse-gray py-3 px-14 rounded-sm transition-all hover:bg-primse-green hover:text-white"
                        >
                          {t("Send request")}
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            if (openRequestModal) {
                              actions.briefcase.setBriefcaseChooseClientToRequest(
                                true
                              );
                              actions.briefcase.setBriefcaseModalSelectClient(
                                true
                              );
                              setCurrentButton(uuid);
                            }
                            actions.briefcase.setBriefcaseModal(true);
                            setPopupOpen(false);
                          }}
                          className="leading-5 my-2 font-bold text-white uppercase bg-primse-gray py-3 px-14 rounded-sm transition-all hover:bg-primse-green hover:text-white"
                        >
                          {t("Send request")}
                        </button>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          )}
          {typeButton === "deleteBasket" && (
            <div className={cs("bg-white  text-sm font-normal")}>
              <div className="flex flex-col">
                <button
                  className="leading-5 my-2 font-bold text-black uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                  onClick={() => {
                    addInvestmentToBriefcase(uuid, investmentData);
                  }}
                >
                  {t("Add to client briefcase")}
                </button>
                {!currentBasketMore && (
                  <button
                    className="leading-5 my-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                    onClick={() => {
                      deleteFromBasket(uuidDeleteFromBasket);
                    }}
                  >
                    {t("Delete from basket")}
                  </button>
                )}
              </div>
            </div>
          )}
          {typeButton === "realestates" && (
            <div className={cs("bg-white  text-sm font-normal")}>
              <div className="flex flex-col">
                {enabledLink
                  ? userRole !== "Deweloper" && (
                      <>
                        <button
                          className="leading-5 my-2 font-bold text-black uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                          onClick={(e) => {
                            addToBasket(
                              e,
                              uuid,
                              currentBasketUuid,
                              investmentData
                            );
                          }}
                        >
                          {t("Add to basket")}
                        </button>
                        <button
                          className="leading-5 my-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                          onClick={() => {
                            addInvestmentToBriefcase(uuid, investmentData);
                          }}
                        >
                          {t("Add to client briefcase")}
                        </button>
                      </>
                    )
                  : null}
                <button
                  className="leading-5 my-2 font-bold text-white uppercase bg-primse-gray py-3 px-14 rounded-sm transition-all hover:bg-primse-green hover:text-gray"
                  onClick={() => {
                    history.push(`/realestate/${uuid}`);
                  }}
                >
                  {t("Details")}
                </button>
              </div>
            </div>
          )}
          {isLoading && <Loader />}
        </Popup>
      )}
    </>
  );
};

export default PopupTrigger;

import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ButtonNavLink from "../../../../../components/ButtonNavLink";
import { checkType } from "../../../../../utils/checkType";
import PopupTrigger from "../../../../../components/PopupTrigger";
import { ChevronDown, ChevronUp, Menu } from "react-feather";
import FlatListViewMobile from "../FlatListViewMobile";
import cs from "classnames";
import { Link, useHistory } from "react-router-dom";
import { editPrice } from "../../../../../utils/numbers";

const ImageRender = ({ img, uuid, enabledLink }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      {!isLoaded && (
        <div
          className="ui placeholder"
          style={{ width: "150px", height: "100px" }}
        ></div>
      )}

      <div
        className={cs("", isLoaded ? "w-full" : "w-0")}
        style={{ opacity: isLoaded ? 1 : 0 }}
      >
        {enabledLink && (
          <Link
            style={{ maxWidth: "200px" }}
            onClick={(e) => {
              e.stopPropagation();
              // history.push(`/realestate/${uuid}`);
            }}
            to={enabledLink ? `/realestate/${uuid}` : null}
          >
            <img
              onLoad={() => setIsLoaded(true)}
              src={`${process.env.REACT_APP_IMAGES_URL}${img}`}
              style={{ maxWidth: "200px" }}
              alt={`investment`}
            />
          </Link>
        )}
        {!enabledLink && (
          <img
            onLoad={() => setIsLoaded(true)}
            src={`${process.env.REACT_APP_IMAGES_URL}${img}`}
            style={{ maxWidth: "200px" }}
            alt={`investment`}
          />
        )}
      </div>
    </>
  );
};

const FlatListView = ({
  flats,
  handleButtonSecondaryMarket,
  offsetTopTable,
  sortRealestates,
  formValues,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      {flats?.length !== 0 ? (
        <div id="flats">
          <div className="border rounded-xl mx-2 px-2  md:hidden">
            <FlatListViewMobile flats={flats} />
          </div>
          <table className="w-full hidden md:table">
            <thead
              className="font-bold uppercase text-xs  sticky top-36 bg-white  sticky   "
              style={{ zIndex: 8, top: offsetTopTable - 2 }}
            >
              <tr className="text-left h-14 mt-10">
                <th className="py-2 px-2 border-b bg-white  "></th>
                <th className="py-2 px-2 border-b   bg-white ">
                  {t("Realestate name")}
                </th>
                <th className="py-2 px-2 border-b  bg-white ">
                  {t("Investment name")}
                </th>
                <th className="py-2 px-2 border-b   bg-white   ">
                  {t("Status")}
                </th>

                <th className="py-2 px-2 border-b  bg-white flex h-14">
                  <button
                    className="leading-5 flex font-bold uppercase text-xs items-center"
                    value="price"
                    onClick={() => {
                      sortRealestates(
                        formValues?.order[0] === "price" &&
                          formValues?.order[1] === "asc"
                          ? "price,desc"
                          : "price,asc"
                      );
                    }}
                  >
                    {t("Price")}
                    {formValues?.order[0] === "price" &&
                    formValues?.order[1] === "asc" ? (
                      <ChevronUp size={14} />
                    ) : (
                      <ChevronDown size={14} />
                    )}
                  </button>
                </th>
                <th className="py-2 px-2 border-b  bg-white   ">
                  <button
                    className="leading-5 flex font-bold uppercase text-xs items-center"
                    value="price"
                    onClick={() => {
                      sortRealestates(
                        formValues?.order[0] === "pricemkw" &&
                          formValues?.order[1] === "asc"
                          ? "pricemkw,desc"
                          : "pricemkw,asc"
                      );
                    }}
                  >
                    {t("Price per m")}
                    <sup>2</sup>
                    {formValues?.order[0] === "pricemkw" &&
                    formValues?.order[1] === "asc" ? (
                      <ChevronUp size={14} />
                    ) : (
                      <ChevronDown size={14} />
                    )}
                  </button>
                </th>
                <th className="py-2 px-2 border-b  bg-white  ">
                  <button
                    className="leading-5 flex font-bold uppercase text-xs items-center"
                    value="price"
                    onClick={() => {
                      sortRealestates(
                        formValues?.order[0] === "area" &&
                          formValues?.order[1] === "asc"
                          ? "area,desc"
                          : "area,asc"
                      );
                    }}
                  >
                    {t("Area")}
                    {formValues?.order[0] === "area" &&
                    formValues?.order[1] === "asc" ? (
                      <ChevronUp size={14} />
                    ) : (
                      <ChevronDown size={14} />
                    )}
                  </button>
                </th>
                <th className="py-2 px-2 border-b  bg-white    ">
                  {t("Rooms")}
                </th>
                <th className="py-2 px-2 border-b  bg-white  ">{t("Floor")}</th>
                <th className="py-2 px-2 border-b  bg-white  ">{t("Type")}</th>
                <th className="py-2 px-2 border-b   bg-white  "> </th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {flats.map((el) => (
                <tr
                  key={el.id}
                  className="border-b h-10 hover:bg-primse-light-green cursor-pointer "
                >
                  <td className="py-0 px-2 border-b  ">
                    <ImageRender
                      img={el.plan_sm}
                      uuid={el.realestate_uuid}
                      enabledLink={
                        el.status === "Available" &&
                        el.investment_status === "On sale"
                      }
                    />
                  </td>
                  <td className="py-0 px-2 border-b ">
                    {el.investment_status === "On sale" ? (
                      <ButtonNavLink
                        uuid={el.realestate_uuid}
                        link={`/realestate/${el.realestate_uuid}`}
                        name={`${el?.building ? `${el?.building}  - ` : ""}${
                          el?.name
                        }`}
                      />
                    ) : (
                      el.name
                    )}
                  </td>
                  <td className="py-0 px-2 border-b ">
                    {el.investment_uuid &&
                      el.investment_status === "On sale" && (
                        <ButtonNavLink
                          uuid={el.investment_uuid}
                          link={`/investment/${el.investment_uuid}`}
                          name={el.investment_name}
                          handleButtonSecondaryMarket={
                            handleButtonSecondaryMarket
                          }
                        />
                      )}
                    {el.investment_status !== "On sale" && el.investment_name}
                  </td>
                  <td className="py-0 px-2 border-b   ">
                    {el.status ? t(el.status) : t("No data")}
                  </td>
                  <td className="py-0 px-2 border-b  ">
                    {el?.price || el?.status === "Available" ? (
                      el?.price && el?.ask_for_price !== "1" ? (
                        <p
                          className={cs(
                            el?.is_in_promotion === "1" &&
                              "text-red-600 font-semibold"
                          )}
                        >
                          {editPrice(el.price)} PLN
                        </p>
                      ) : (
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            history.push(
                              `/questions?r_uuid=${el?.realestate_uuid}&i_uuid=${el?.investment_uuid}`
                            );
                          }}
                        >
                          {t("Ask about price")}
                        </span>
                      )
                    ) : (
                      t("No data")
                    )}
                  </td>
                  <td className="py-0 px-2 border-b  ">
                    {el?.pricemkw || el?.status === "Available" ? (
                      el?.pricemkw && el?.ask_for_price !== "1" ? (
                        `${editPrice(el?.pricemkw)} PLN`
                      ) : (
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            history.push(
                              `/questions?r_uuid=${el?.realestate_uuid}&i_uuid=${el?.investment_uuid}`
                            );
                          }}
                        >
                          {t("Ask about price")}
                        </span>
                      )
                    ) : (
                      t("No data")
                    )}
                  </td>
                  <td className="py-0 px-2 border-b   ">
                    {el.area ? `${el.area} m` : t("No data")}
                    {el.area && <sup style={{ zIndex: "-1" }}>2</sup>}
                  </td>
                  <td className="py-0 px-2 border-b   ">
                    {el.rooms ? el.rooms : t("No data")}
                  </td>
                  <td className="py-0 px-2 border-b ">
                    {el.floor || el?.floor === 0 ? el.floor : t("No data")}
                  </td>
                  <td className="py-0 px-2 border-b">
                    {el.type ? t(checkType(el.type)) : t("No data")}
                  </td>
                  <td className="py-3 px-4 border-b    ">
                    <PopupTrigger
                      uuid={el.realestate_uuid}
                      investmentData={el.name}
                      aftermarket={true}
                      typeButton="realestates"
                      enabledLink={
                        el.status === "Available" &&
                        el.investment_status === "On sale"
                      }
                    >
                      <div className="mr-2.5 text-primse-green relative w-8 cursor-pointer">
                        <Menu
                          className="ml-2 text-primse-green   cursor-pointer"
                          size={24}
                        />
                      </div>
                    </PopupTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-sm w-full py-8 px-4 ">
          {t("There are no matching premises in the investment")}
        </div>
      )}
    </>
  );
};

FlatListView.propTypes = {
  flats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uuid: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.string,
      pricemkw: PropTypes.string,
      area: PropTypes.string,
      rooms: PropTypes.string,
      floor: PropTypes.string,
      status: PropTypes.string,
      investment_id: PropTypes.string,
      investment_uuid: PropTypes.string,
      investment_city: PropTypes.string,
      investment_lat: PropTypes.string,
      investment_long: PropTypes.string,
      plan: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

export default FlatListView;
